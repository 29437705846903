<template>
	<div class="indexContainer">
		<div class="swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide part_1">
					<img src="../../public/images/indexbg2.png" />
					<div class="centerInfo"></div>
					<button class="centerBtn" @click="$router.push('/product')">查看详情</button>
				</div>
				<div class="swiper-slide part_2">
					<div class="imgGroup">
						<img src="../../public/images/secondpic1.png" swiper-animate-effect="fadeInUp" swiper-animate-duration="0.5s" swiper-animate-delay="0.1s" class="ani img1 aniImg" />
						<img src="../../public/images/secondpic2.png" swiper-animate-effect="fadeInUp" swiper-animate-duration="0.5s" swiper-animate-delay="0.2s" class="ani img2 aniImg" />
						<img src="../../public/images/secondpic3.png" swiper-animate-effect="fadeInUp" swiper-animate-duration="0.5s" swiper-animate-delay="0.3s" class="ani img3 aniImg" />
						<img src="../../public/images/secondpic4.png" swiper-animate-effect="fadeInUp" swiper-animate-duration="0.5s" swiper-animate-delay="0.4s" class="ani img4 aniImg" />
						<img src="../../public/images/secondpic5.png" swiper-animate-effect="fadeInUp" swiper-animate-duration="0.5s" swiper-animate-delay="0.5s" class="ani img5 aniImg" />
					</div>
					<div class="infoGroup">
						<div class="t0"><span class="t0_font">01</span><span class="tabLine"></span></div>
						<div class="t1">电力物联网</div>
						<div class="infoItem ani" swiper-animate-effect="fadeInRight" swiper-animate-duration="0.5s" swiper-animate-delay="0.5s">
							<div class="infoTitle" @click="linkTo('product','611c502a10dad2a17d0e46c5635dfcef')">数字孪生变电站平台</div>
							<div style="clear: both;"></div>
							<div class="infoContent">数字孪生变电站平台是充分用设备物理模型、传感器更新、运行历史等数据的集成，实现对实体变电站的全生命周期过程监测。</div>
						</div>
						<div class="infoItem ani" swiper-animate-effect="fadeInRight" swiper-animate-duration="0.5s" swiper-animate-delay="0.6s">
							<div class="infoTitle" @click="linkTo('product','cda1117d2a730f066a2834ea4581a834')">变电站智能辅助系统</div>
							<div style="clear: both;"></div>
							<div class="infoContent">变电站智能辅助系统可实现变电站辅助设备数据的集中业务展示及应用，利用系统客户端 向变电集控站提供数据展示、远程控制、异常告警、视频联动及分析等应用功能。</div>
						</div>
						<div class="infoItem ani" swiper-animate-effect="fadeInRight" swiper-animate-duration="0.5s" swiper-animate-delay="0.7s">
							<div class="infoTitle" @click="linkTo('product','8deb7ed23a7c5f4cd52891f11bd51e0f')">配电站房智能辅助系统</div>
							<div style="clear: both;"></div>
							<div class="infoContent">配电房智能化解决方案是应用物联网、人工智能、图像识别、RFID 等先进技术，提高配电站房智能化水平，减少巡检人员工作强度，保证配电站房运行维护的安全性和可靠性。</div>
						</div>
						<div class="infoItem ani" swiper-animate-effect="fadeInRight" swiper-animate-duration="0.5s" swiper-animate-delay="0.8s">
							<div class="infoTitle" @click="linkTo('product','c15c90e21e440b2a0380692aaa4ef5ca')">台区智能物联系统</div>
							<div style="clear: both;"></div>
							<div class="infoContent">台区异常智能研判系统通过对展配电台区运行状态监控，结合大数据、人工智能等技术实现对配电台区运行状态精准监控，对数据进行在线分析与深度挖掘， 实现广泛的配电网运行状态全景感知。</div>
						</div>
						<div class="infoItem ani" swiper-animate-effect="fadeInRight" swiper-animate-duration="0.5s" swiper-animate-delay="0.8s">
							<div class="infoTitle" @click="linkTo('product','7dd23629134777320951dc2503747590')">物联监测产品</div>
							<div style="clear: both;"></div>
							<div class="infoContent">物联监测产品包含：SF6气体浓度在线监测装置、水位在线监测装置、智能除湿机、智能锁等</div>
						</div>
					</div>
				</div>
				<div class="swiper-slide part_3">
					<div class="banner">
						<div class="bContainer">
							<div class="b_title ani" swiper-animate-effect="fadeInLeft" swiper-animate-duration="0.5s" swiper-animate-delay="0s">
								<div class="t0"><span class="t0_font">02</span><span class="tabLine"></span></div>
								<div class="t1">电网数据服务</div>
							</div>
						</div>
					</div>
					<div class="bottomContainer">
						<ul class="items">
							<li>
								<div @click="linkTo('service','64da73ebd3033af32415a21cd9e843df')" class="item ani" swiper-animate-effect="zoomIn" swiper-animate-duration="0.5s" swiper-animate-delay="0.2s" imageAttr="img1">
									<div class="shade"></div>
									<img src="../../public/images/thirdicon1.png" />
									<span class="itemFont">营配调贯通一致性核查</span>
								</div>
							</li>
							<li>
								<div @click="linkTo('service','c4642db4f15de91c2c12d98ee4bda36b')" class="item ani" swiper-animate-effect="zoomIn" swiper-animate-duration="0.5s" swiper-animate-delay="0.3s" imageAttr="img2">
									<div class="shade"></div>
									<img src="../../public/images/thirdicon2.png" />
									<span class="itemFont">电缆系统建设及数据采录</span>
								</div>
							</li>
							<li>
								<div @click="linkTo('service','66464614324bbd99d7f8f994daaac079')" class="item ani" swiper-animate-effect="zoomIn" swiper-animate-duration="0.5s" swiper-animate-delay="0.4s" imageAttr="img3">
									<div class="shade"></div>
									<img src="../../public/images/thirdicon3.png" />
									<span class="itemFont">架空线路无人机智慧巡检</span>
								</div>
							</li>
							<li>
								<div @click="linkTo('service','dc267a422dc5e68318d8331f4b21dc45')" class="item ani" swiper-animate-effect="zoomIn" swiper-animate-duration="0.5s" swiper-animate-delay="0.5s" imageAttr="img4">
									<div class="shade"></div>
									<img src="../../public/images/thirdicon4.png" />
									<span class="itemFont">电网资源三维建模</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="swiper-slide part_3">
					<div class="banner">
						<div class="bContainer">
							<div class="b_title ani" swiper-animate-effect="fadeInLeft" swiper-animate-duration="0.5s" swiper-animate-delay="0s">
								<div class="t0"><span class="t0_font">03</span><span class="tabLine"></span></div>
								<div class="t1">电网实施运维服务</div>
							</div>
						</div>
					</div>
					<div class="bottomContainer">
						<ul class="items">
							<li>
								<div @click="linkTo('power','60cc42d84a1625536976746005efc2cb')" class="item ani" swiper-animate-effect="zoomIn" swiper-animate-duration="0.5s" swiper-animate-delay="0.2s" imageAttr="img1">
									<div class="shade"></div>
									<img src="../../public/images/thirdicon1.png" />
									<span class="itemFont">设备(资产)精益管理系统(PMS2.0)<br/>运维服务</span>
								</div>
							</li>
							<li>
								<div @click="linkTo('power','6193fb10230d39bd56bff3aeb0b3f6b9')" class="item ani" swiper-animate-effect="zoomIn" swiper-animate-duration="0.5s" swiper-animate-delay="0.4s" imageAttr="img3">
									<div class="shade"></div>
									<img src="../../public/images/thirdicon3.png" />
									<span class="itemFont">新一代电网发展信息系统(PIS2.0)<br/>实施运维服务</span>
								</div>
							</li>

							<li>
								<div @click="linkTo('power','0766341858929206581ece6e90508c8f')" class="item ani" swiper-animate-effect="zoomIn" swiper-animate-duration="0.5s" swiper-animate-delay="0.5s" imageAttr="img4">
									<div class="shade"></div>
									<img src="../../public/images/thirdicon4.png" />
									<span class="itemFont">一体化电量与线损管理系统<br/>运维服务</span>
								</div>
							</li>
							<li>
								<div @click="linkTo('power','46a21a14a91b253e1378c8b4fb96830a')" class="item ani" swiper-animate-effect="zoomIn" swiper-animate-duration="0.5s" swiper-animate-delay="0.3s" imageAttr="img2">
									<div class="shade"></div>
									<img src="../../public/images/thirdicon2.png" />
									<span class="itemFont">变电信息综合处理系统实时<br/>运维服务</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="swiper-slide part_4">
					<img class="titleImg" src="../../public/images/fourthtitle.png"/>
					<div class="bottomContainer">
						<ul>
							<li class="ani" swiper-animate-effect="zoomIn" swiper-animate-duration="0.5s" swiper-animate-delay="0s">
								<div class="i_title"><img src="../../public/images/footpic1.png"/>地址：</div>
								<div class="i_content">南京市雨花台区雨花街道民智路2-1号喜马拉雅M座19层</div>
							</li>
							<li class="ani" swiper-animate-effect="zoomIn" swiper-animate-duration="0.5s" swiper-animate-delay="0.2s">
								<div class="i_title"><img src="../../public/images/footpic2.png"/>固话：</div>
								<div class="i_content">025-85506345</div>
							</li>
							<li class="ani" swiper-animate-effect="zoomIn" swiper-animate-duration="0.5s" swiper-animate-delay="0.4s">
								<div class="i_title"><img src="../../public/images/footpic3.png"/>传真：</div>
								<div class="i_content">025-85506345</div>
							</li>
							<li class="ani" swiper-animate-effect="zoomIn" swiper-animate-duration="0.5s" swiper-animate-delay="0.6s">
								<div class="i_title"><img src="../../public/images/footpic4.png"/>邮箱：</div>
								<div class="i_content">market@auto-sense.com.cn</div>
							</li>
							<li class="ani" swiper-animate-effect="zoomIn" swiper-animate-duration="0.5s" swiper-animate-delay="0.8s">
								<div class="i_title"><img src="../../public/images/footpic5.png"/>网址：</div>
								<div class="i_content">www.auto-sense.com.cn</div>
							</li>
						</ul>
						<div class="copyright">Copyright&copy;南京奥途信息技术有限公司 <a style="font-size:16px; text-decoration: underline;" href="https://beian.miit.gov.cn/">苏ICP备17036830号-1</a> 版权所有 All Rights Reserved.</div>
					</div>
					
				</div>
			</div>
			<!-- Add Pagination -->
			<div class="swiper-pagination" style="left:10px;"></div>
		</div>
	</div>
</template>

<script>
	import Swiper from 'swiper'
	import 'swiper/dist/css/swiper.min.css'
	import '../assets/animate.min.css'
	import '../assets/index.css'
	export default {
		name: 'MainPage',

		data() {
			return {
				mySwiper: {}
			}
		},
		computed: {

		},
		mounted() {
			this.initSwiper();
		},
		methods: {
			initSwiper: function() {

				let _this = this;
				window.setTimeout(function() {
					_this.mySwiper = new Swiper('.swiper-container', {
						direction: 'vertical',
						loop:true,
						slidesPerView: 1,
						spaceBetween: 30,
						mousewheel: true,
						autoplay: {
							delay: 3000,
							
						},
						pagination: {
							el: '.swiper-pagination',
							clickable: true,
						},
						on: {
							init: function() {
								_this.$swiperAnimateCache(this);
								_this.$swiperAnimate(this);
							},
							slideChange: function() {
								// console.log(_this);
								_this.$swiperAnimate(this);
							},
						}
					});
				}, 100)

			},
			linkTo(router,dataId){
				this.$router.push({name:router,params:{id:dataId}});
			}
		}
	}
</script>

<style scoped="scoped">
	.infoTitle{
		cursor: pointer;
	}
	.items .item{
		cursor:pointer;
	}
	.copyright{
		height:2rem;
		width: 100%;
		line-height: 2rem;
		color:#FFFFFF;
		border-top:1px solid #FFFFFF;
		text-align: center;
		font-size: 0.6rem;
		position: absolute;
		bottom: 0;
		z-index: 9999;
	}
</style>
